import React from "react"

import Layout from "../../components/layout"
import Hero from "../../components/Hero"
import { PeopleHunt } from "../../components/PeopleHunt"
import { Contact } from "../../components/Contact"
import SEO from "../../components/seo"
import ReferenceContainer from "../../components/ReferenceContainer"

const Integration = () => {
  const tags = [
    "LogicApps",
    "BizTalk",
    ".NET",
    "XML",
    "JSON",
    "EDIFACT",
    "EDI",
    "ICC",
    "ServiceBus",
    "Cloud",
    "ESB",
    "EAI",
    "EIP",
    "REST",
    "SOAP",
    "Business Rules",
  ]

  return (
    <Layout>
      <SEO title="Integration" />
      <Hero
        heading={`Integration`}
        goto="references"
        scrollTitle="Integration"
      />
      <ReferenceContainer></ReferenceContainer>
      <PeopleHunt />
      <Contact />
    </Layout>
  )
}

export default Integration
